

import React, { useState, useEffect } from 'react';
import './App.css';
import { Button, Col, Row } from 'react-bootstrap';
import { baseUrl } from './const';
import Settings from './Settings';

const NameSlipList = () => {
  const [nameSlip, setNameSlip] = useState();
  const [complete, setComplete] = useState();
  const [singleImage, setSingleImage] = useState();
  const [margeOrder, setMargeOrder  ] = useState();
  const [clickedButtons, setClickedButtons] = useState([]);
  const [singleClickedButtons, setSingleClickedButtons] = useState([]);
  const [margeButtons, setMargeButtons] = useState([]);

const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/currant/files`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      setNameSlip(jsonData?.files);
      setSingleImage(jsonData?.singleList)
      setMargeOrder(jsonData?.margeOrder)
      setComplete(jsonData?.complete)
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const getPrevNameSlip = async () => {
  //   try {
  //     const response = await fetch(`${baseUrl}/api/previous/files`);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const jsonData = await response.json();
  //     setPrevNameSlip(jsonData?.files);
     
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  useEffect(() => {
    fetchData();
    if(complete === 0){
      setInterval(() => {
        fetchData();
      }, 15000)
    }
  }, [complete]);

  const handleDownload = async (index, url, count) => {
    const downloadresult = count === 1 ? clickedButtons :
               count === 2 ? singleClickedButtons :
               count === 3 ? margeButtons : null;
    const setDownloadresult = count === 1 ? setClickedButtons :
               count === 2 ? setSingleClickedButtons :
               count === 3 ? setMargeButtons : null;
    if ((downloadresult).includes(index)) {
      setDownloadresult(downloadresult.filter(item => item !== index));
    } else {
      setDownloadresult([...downloadresult, index]);
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        // Extract filename from URL
        const filename = url.split('/').pop();
  
        const blob = await response.blob();
  
        // Create a temporary URL for the blob
        const tempUrl = window.URL.createObjectURL(blob);
  
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = tempUrl;
        link.setAttribute('download', filename);
  
        // Append the link to the body and trigger the click event
        document.body.appendChild(link);
        link.click();
  
        // Clean up by removing the link and revoking the temporary URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(tempUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }
  };
  

  const deleteData = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/api/deleteCurrentFiles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          key : true
        })
      });
      const data = await response.json(); 
      if (data?.status === 1) {
        setInterval(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
 
  return (
    <div className='container'>
          <Settings/>
        <Row>
          <h2 className='text-success text-center'>{complete ? "Successfuly Done" : ''}</h2>
        </Row>
        <Row>
           <Col md={6}>
           <h1>
           Group Image Pdf
            </h1>
           </Col>
           <Col md={6} className='text-align-end'>
              <Button onClick={deleteData} variant='danger' className='float-right'>
                Delete
              </Button>
           </Col>
        </Row>

        <Row>
          {
              !!nameSlip && nameSlip?.map((data, index) =>{
                  const filename = data?.split('/').pop()
                  return(
                      <Col className='mt-2' md={2} key={index}>
                              <Button variant={clickedButtons?.includes(index) ? 'success' : 'primary'} className='w-100 word-wrap-break' onClick={() => {
                                  handleDownload(index, data, 1)
                                  }}>{filename}
                              </Button>
                      </Col>
                      )
                  })
              }
        </Row>
        <hr/>
        <Row>
           <Col md={6}>
           <h1>
                Single Image Pdf(For testing)
            </h1>
           </Col>
        </Row>
        <Row>
          {
              (singleImage?.length === 0) ? <h2 className='text-primary text-center'>Pls wait......</h2> : singleImage?.map((data, index) =>{
                  const filename = data?.split('/').pop()
                  return(
                      <Col className='mt-2' md={2} key={index}>
                              <Button variant={singleClickedButtons?.includes(index) ? 'success' : 'primary'} className='w-100 word-wrap-break' onClick={() => {
                                  // handleDownloadSingle(index, data)
                                  handleDownload(index, data, 2)
                                  }}>{filename}
                              </Button>
                      </Col>
                      )
                  })
              }
        </Row>
        <hr/>
        <Row>
           <Col md={6}>
            <h1>Marge Order</h1>
           </Col>
        </Row>
        <Row>
          {
              (margeOrder?.length === 0) ? <h2 className='text-primary text-center'>Pls wait......</h2> : margeOrder?.map((data, index) =>{
                  const filename = data?.split('/').pop()
                  return(
                      <Col className='mt-2' md={2} key={index}>
                              <Button variant={margeButtons?.includes(index) ? 'success' : 'primary'} className='w-100 word-wrap-break' onClick={() => {
                                   handleDownload(index, data, 3)
                                  //handleDownloadSingle(index, data)
                                  }}>{filename}
                              </Button>
                      </Col>
                      )
                  })
              }
        </Row>
    </div>
  );
};

export default NameSlipList;
