import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NameSlipList from './NameSlipList';
import Invoice from './Invoice';
import ShipmentTool from './ShipmentTool';
import MissingOrders from './MissingOrders';
// MissingOrders

function App() {
  return (
    <>  
       <Router>
        <Routes>
          <Route path="/" element={ <NameSlipList/>} />
          <Route path="/invoice" element={ <Invoice/>} />
          <Route path="/shipment" element={ <ShipmentTool/>} />
          <Route path="/missingorders" element={ <MissingOrders/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
