

import React, { useState, useEffect } from 'react';
import './App.css';
import {  Button, Col, Row, Table } from 'react-bootstrap';
import SettingAction from './SettingAction';
import { baseUrl } from './const';
import { Link } from 'react-router-dom';

const Settings = () => {
  const [settingsList, setSettingsList] = useState();


const getSettings = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/getsetting?key=files`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      setSettingsList(jsonData?.setting);
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getSettings();
  }, [ ]);


  return (
    <div className='container mt-5'>
        <Row>
            <Col md={6}>
              <h1>
                  Settings
              </h1>
            </Col>
            <Col md={6} className='text-align-end'>
            <Button variant='info' className='mx-1 p-1' onClick={() => window.location.reload()}>Reload Data</Button>
              <Link to="/invoice"><Button className='p-1' variant='success'>Go to Invoice</Button></Link>
              {/* <Link to="/missingorders"><Button className='p-1' variant='warning'>Go To Pending Orders</Button></Link> */}
              <Link to="/shipment"><Button className='p-1' variant='info'>Go To Shipment</Button></Link>
            </Col>
        </Row>
      <Row>
        <Table>
            <thead>
                <tr>
                    <th>Sr No.</th>
                    <th>Name</th>
                    <th>Key</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Action</th>
                </tr>
                {!!settingsList && settingsList?.map((data, index) =>{
                return(
                    <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{data?.name}</td>
                        <td>{data?.key}</td>
                        <td>{data?.startDate}</td>
                        <td>{data?.endDate}</td>
                        <td>
                            <SettingAction start={data?.startDate} id={data?.id} end={data?.endDate}/>
                        </td>
                    </tr>
                    )
                })
                }
                
            </thead>
        </Table>
      </Row>
      {/* <hr/>
      <Row>
            <h1>
            Get Previous Files <Button variant='warning' onClick={() => getPrevNameSlip()}>
                    <b>Click Here</b>
                </Button>
            </h1>
        </Row>
       */}
    </div>
  );
};

export default Settings;
