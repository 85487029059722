

import React, { useState, useEffect } from 'react';
import './App.css';
import { Button, Col, Row } from 'react-bootstrap';
import { baseUrl } from './const';
import ShipmentSetting from './ShipmentSetting';

const ShipmentTool = () => {
  const [invoice, setInvoice] = useState();
  const [complete, setComplete] = useState();
  const [clickedButtons, setClickedButtons] = useState([]);

const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/shipment/files`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      setInvoice(jsonData?.files);
      setComplete(jsonData?.complete)
     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
    if(complete === 0){
      setInterval(() => {
        fetchData();
      }, 15000)
    }
  }, [complete]);

  const handleDownload = async (index, url, count) => {
    const downloadresult = count === 1 ? clickedButtons : null;
    const setDownloadresult = count === 1 ? setClickedButtons : null;
    if ((downloadresult).includes(index)) {
      setDownloadresult(downloadresult.filter(item => item !== index));
    } else {
      setDownloadresult([...downloadresult, index]);
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Extract filename from URL
        const filename = url.split('/').pop();
        const blob = await response.blob();
        // Create a temporary URL for the blob
        const tempUrl = window.URL.createObjectURL(blob);
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = tempUrl;
        link.setAttribute('download', filename);
  
        // Append the link to the body and trigger the click event
        document.body.appendChild(link);
        link.click();
        // Clean up by removing the link and revoking the temporary URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(tempUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }
  };
  

  const deleteData = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/api/delete/shipmentfiles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          key : true
        })
      });
      const data = await response.json(); 
      if (data?.status === 1) {
        setInterval(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
 
  return (
    <div className='container'>
          <ShipmentSetting/>
        <Row>
           <Col md={6}>
           <h1>
           Shipment Tool
            </h1>
           </Col>
           <Col md={6} className='text-align-end'>
              <Button onClick={deleteData} variant='danger' className='float-right'>
                Delete
              </Button>
           </Col>
        </Row>
        <Row>
          <h3 className='text-success text-center'>{complete ? "Successfuly Done" : ''}</h3>
        </Row>
        <Row>
          {
              !!invoice && invoice?.map((data, index) =>{
                  const filename = data?.split('/').pop()
                  return(
                      <Col className='mt-2' md={3} key={index}>
                              <Button variant={clickedButtons?.includes(index) ? 'success' : 'primary'} className='w-100 word-wrap-break' onClick={() => {
                                  handleDownload(index, data, 1)
                                  }}>{filename}
                              </Button>
                      </Col>
                      )
                  })
              }
        </Row>
    </div>
  );
};

export default ShipmentTool;
