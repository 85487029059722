import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { baseUrl } from './const';
import moment from 'moment';

function MissingOrderSettingAction({start, end, id, orderids}) {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [missingordersArea, setMissingordersArea] = useState(orderids);
  const [response, setResponse] = useState(null);
  const [status, setStatus] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      console.log(missingordersArea);
      const response = await fetch(`${baseUrl}/api/orderidbypdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ids : missingordersArea.split(','),
          startDate : moment(startDate).format('YYYY-MM-DD, HH:mm:ss'), 
          endDate : moment(endDate).format('YYYY-MM-DD, HH:mm:ss'), id})
          
      });
      const data = await response.json(); 
      setResponse(data?.message); 
      setStatus(data?.status)
      if (data?.status === 1) {
        setInterval(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
 

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
       Edit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <InputGroup className='mt-4'>
                <InputGroup.Text id="inputGroup-sizing-lg">OrderIds</InputGroup.Text>
                <Form.Control
                value={missingordersArea}
                onChange={(e) => setMissingordersArea(e.target.value)}
                as="textarea"
                row={5}
                step="1"
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text id="inputGroup-sizing-lg">Start Date</InputGroup.Text>
                <Form.Control
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="datetime-local"
                step="1"
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                />
            </InputGroup>
            <InputGroup className='mt-4'>
                <InputGroup.Text id="inputGroup-sizing-lg">End Date</InputGroup.Text>
                <Form.Control
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                type="datetime-local"
                step="1"
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                />
            </InputGroup>
            <Form.Select className='mt-4' aria-label="Default select example"
            onChange={(e) => {
             if(e.target.value === "Today"){
              setStartDate(moment().format('YYYY-MM-DD, 00:00:00'))
              setEndDate(moment().format('YYYY-MM-DD, HH:mm:ss'))
             }
             if(e.target.value === "YesterDay"){
              setStartDate(moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD 00:00:00'))
              setEndDate(moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD, HH:mm:ss'))
             }
            }}>
              <option>Select Day</option>
              <option value="Today">Today</option>
              <option value="YesterDay">YesterDay</option>
            </Form.Select>
            <hr/>
            <p className={status === 1 ? "text-success" : "text-danger"}><b>{response}</b></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSubmit}>
            Update
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MissingOrderSettingAction;